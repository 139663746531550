import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { viewport } from '../viewport';

let scrollContainer: HTMLElement | null;
let scrollContainerWidth = viewport.width;
let header: HTMLElement | null;
let headerHeight = 0;

function getContainerSizes() {
    if (scrollContainer) {
        scrollContainerWidth = scrollContainer.scrollWidth;
    }
    if (header) {
        headerHeight = header.offsetHeight;
        console.log(headerHeight);
    }
}
function init() {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const isMobile = window.matchMedia('(max-width: 1159px)').matches;
    if (!isMobile) {
        scrollContainer = document.querySelector<HTMLElement>('.js-scroll-container');
        header = document.querySelector('.js-header');
    }

    const teamList = document.querySelector('.js-team-list');
    const founderSection = document.querySelector('.js-founder-section');

    if (scrollContainer) {
        getContainerSizes();

        if (!isMobile) {
            ScrollTrigger.normalizeScroll(true);
    
            gsap.to(scrollContainer, {
                x: () => `-${scrollContainerWidth - viewport.width}px`,
                ease: 'none',
                scrollTrigger: {
                    trigger: scrollContainer,
                    start: () => 'top top',
                    end: () => `+=${scrollContainerWidth}`,
                    scrub: 0,
                    pin: true,
                    anticipatePin: 1,
                    snap: 1,
                    invalidateOnRefresh: true,
                },
            });
    
            ScrollTrigger.create({
                trigger: teamList,
                start: 'top bottom',
                onEnter: () => {
                    if (teamList) {
                        gsap.to(window, {
                            scrollTo: {
                                y: teamList,
                                offsetY: headerHeight - 40,
                            },
                            duration: 1,
                        });
                    }
                },
            });
    
            ScrollTrigger.create({
                trigger: teamList,
                start: `top ${headerHeight + 100}`,
                onLeaveBack: () => {
                    if (founderSection) {
                        gsap.to(window, {
                            scrollTo: {
                                y: founderSection,
                            },
                            duration: 1,
                        });
                    }
                },
            });
    
            ScrollTrigger.addEventListener('refreshInit', getContainerSizes);
        }
    }
}

export default { init };
