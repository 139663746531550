<template>
    <div class="device-item">
        <div class="device-item__img-container">
            <div class="responsive device-item__img-wrapper">
                <img :src="img" class="responsive__item device-item__img" :alt="name" :title="name" />
            </div>
        </div>
        <a :href="href" class="device-item__name">{{ name }}</a>
        <div v-if="description" class="device-item__description">{{ description }}</div>
        <div class="device-item-bottom">
            <div class="device-item-price">
                <div class="b-price">
                    <div class="b-price__current">
                        <span>{{ toCurrency(priceDiscounted) }}</span>
                    </div>
                    <div v-if="priceDiscounted !== price" class="b-price__old">{{ toCurrency(price) }}</div>
                    <div class="b-price__nds">с ндс</div>
                </div>
            </div>
            <v-counter
                :value="amount"
                :min-value="minValue"
                :delta="counterDelta"
                @change="onCounterChange"
            ></v-counter>
        </div>
    </div>
</template>

<script lang="ts">
import VCounter from './AppCounter.vue';
import { toCurrency } from '../../utils/to-currency';

export default {
    components: { VCounter },

    props: {
        amount: {
            type: Number,
            default: 1,
        },
        minValue: {
            type: Number,
            default: 0,
        },
        counterDelta: {
            type: Number,
            default: 1,
        },
        id: {
            type: String,
        },
        img: {
            type: String,
        },
        name: {
            type: String,
        },
        description: {
            type: String,
        },
        href: {
            type: String,
        },
        price: {
            type: Number,
        },
        priceDiscounted: {
            type: Number,
        },
    },

    setup(props, { emit }) {
        function onCounterChange({ value }) {
            emit('counter-change', { id: props.id, value });
        }

        return { toCurrency, onCounterChange };
    },
};
</script>

<style lang="scss">
// @import '../../../css/base/variables';
@import '../../../css/utils/mixins';

.device-item {
    background-color: #fff;
    padding: 22px 26px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        padding: 20px 24px;
    }
}

.device-item__img-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1440px) {
        width: 70%;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
}

.device-item__img-wrapper {
    padding-top: 100% !important;
    margin-bottom: 30px;
}

.device-item__name {
    font-size: 22px;
    line-height: 1.27;
    letter-spacing: -0.05em;
    color: #002450;
    margin-bottom: 10px;
    transition: color 0.2s ease;

    @media screen and (max-width: 767px) {
        font-size: 20px;
        margin-bottom: 8px;
    }
}

.device-item__description {
    font-weight: 500;
    opacity: 0.6;
    font-size: 11px;
    line-height: 1.27;
    margin-bottom: 30px;

    @media screen and (max-width: 1680px) {
        margin-bottom: 20px;
    }

    @media screen and (max-width: 1440px) {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
}

.device-item-bottom {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (min-width: 1280px) and (max-width: 1365px),
        (min-width: 576px) and (max-width: 1024px),
        (max-width: 370px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .device-item-price {
        @media screen and (min-width: 1280px) and (max-width: 1365px),
            (min-width: 576px) and (max-width: 1024px),
            (max-width: 370px) {
            margin-bottom: 10px;
        }
    }

    .counter {
        @media screen and (min-width: 1280px) and (max-width: 1365px),
            (min-width: 576px) and (max-width: 1024px),
            (max-width: 370px) {
            width: 100%;
        }
    }
}

.device-item-price {
    padding-right: 10px;

    @media screen and (max-width: 370px) {
        padding-right: 0;
        margin-bottom: 10px;
    }

    .b-price {
        flex-direction: column;
        flex-wrap: nowrap;

        @media screen and (max-width: 1600px) {
            font-size: 20px;
        }

        @media screen and (max-width: 1366px) {
            font-size: 18px;
        }
    }
}
</style>
