const firstSlide = document.querySelector('.js-about-first-slide');

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                document.body.classList.add('white-version');
            } else {
                document.body.classList.remove('white-version');
            }
        });
    },
    { rootMargin: '0px' },
);

function init() {
    if (firstSlide) {
        observer.observe(firstSlide);
    }
}

const _module = { init };

export default _module;
