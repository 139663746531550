import Swiper, { Navigation, Pagination, Mousewheel, EffectFade, Keyboard } from 'swiper';

function init() {
    const slider = document.querySelector('.js-swiper-vertical');
    const mainHorizontalSlider = document.querySelector('.js-main-horizontal-swiper');

    const swiper = new Swiper(slider, {
        direction: 'vertical',
        effect: 'fade',
        simulateTouch: false,
        slidesPerView: 1,
        keyboard: true,
        spaceBetween: 0,
        speed: 800,
        mousewheel: true,
        modules: [Navigation, Pagination, Mousewheel, EffectFade, Keyboard],
        pagination: {
            el: '.js-vertical-swiper-pagination',
            clickable: true,
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                if (current === swiper.slides.length) {
                    return '<span class="swiper-pagination-current" ><span>0' + current + "</span></span>"
                } else {
                    return (
                        '<span class="swiper-pagination-current" ><span>0' + current + "</span></span>" +
                        '<span class="swiper-pagination-total"><span>0' + (current + 1) + '</span></span>'
                    );
                }
            },
        },
        navigation: {
            nextEl: '.js-swiper-vertical-button-next',
            prevEl: '.js-swiper-vertical-button-prev',
        },
        fadeEffect: {
            crossFade: true,
        },
        on: {
            slideChangeTransitionStart: function (swiper) {
                let activeSlideIndex = swiper.activeIndex;
                let bodyElement = document.body;
                let horizontalSwiper = mainHorizontalSlider?.swiper;

                if (activeSlideIndex === 1) {
                    bodyElement.classList.remove('white-version');
                }

                if (activeSlideIndex === 0 && [3, 5].includes(horizontalSwiper.activeIndex)) 
                {
                    bodyElement.classList.add('white-version');
                }
            },
        },
    });
}

const _module = { init };

export default _module;
