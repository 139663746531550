import './Tabs.scss';
import { LitElement } from 'lit';

export interface Tabs {
    selected: number;
    _togglers: HTMLElement[];
    _contents: HTMLElement[];
}

export class Tabs extends LitElement {
    constructor() {
        super();
        this._onTogglerClick = this._onTogglerClick.bind(this);

        this.selected = 0;
        this._togglers = [];
        this._contents = [];
    }

    static get properties() {
        return {
            selected: {
                type: Number,
                reflect: true,
            },
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        this._togglers = Array.from(this.renderRoot.querySelectorAll<HTMLElement>('[data-tabs-toggler]'));
        this._contents = Array.from(this.renderRoot.querySelectorAll<HTMLElement>('[data-tabs-content]'));
        this.selected = 0;

        this._togglers.forEach((el) => {
            el.addEventListener('click', this._onTogglerClick);
        });
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        super.attributeChangedCallback(name, oldValue, newValue);

        if (name === 'selected') {
            if (oldValue && newValue) {
                const index = parseInt(newValue, 10);

                this._contents.forEach((el) => {
                    el.hidden = true;
                });
                this._contents[index].hidden = false;

                this._togglers.forEach((el) => {
                    el.removeAttribute('data-active');
                });
                this._togglers[index]?.setAttribute('data-active', '');

                this.dispatchEvent(
                    new CustomEvent('tab-change', {
                        composed: true,
                        detail: { oldValue: parseInt(oldValue, 10), newValue: index },
                    }),
                );
            }
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._togglers.forEach((el) => {
            el.addEventListener('click', this._onTogglerClick);
        });
        this._togglers = [];
        this._contents = [];
    }

    _onTogglerClick(event: any) {
        const id = event.target.closest('[data-tabs-toggler]')?.dataset.tabsToggler;

        if (id) {
            this.setTabById(id);
        }
    }

    setTabById(id: string) {
        this.selected = this._contents.findIndex((el) => el.dataset.tabsContent === id);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-tabs': Tabs;
    }
}
