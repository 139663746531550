import LazyLoad from 'vanilla-lazyload';

function init() {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
    });

    document.addEventListener('DOMContentLoaded', init);

    if (window.lazyLoadInstance) {
        window.lazyLoadInstance.update();
    }
}

const _module = { init };

export default _module;
