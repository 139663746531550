import Swiper, { Navigation, Pagination } from 'swiper';

type HTMLElementWithSwiper = HTMLElement & {
    swiper: Swiper;
};

function init() {
    const slider = document.querySelector<HTMLElement>('.js-main-horizontal-swiper');
    const verticalSlider = document.querySelector<HTMLElementWithSwiper>('.js-swiper-vertical');

    if (slider) {
        const swiper = new Swiper(slider, {
            direction: 'horizontal',
            effect: 'fade',
            keyboard: true,
            loop: true,
            spaceBetween: 0,
            speed: 800,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination-h',
                clickable: true,
                type: 'bullets',
            },
            navigation: {
                nextEl: '.swiper-button-next-h',
                prevEl: '.swiper-button-prev-h',
            },
            on: {
                slideChangeTransitionStart: function (swiper) {
                    let activeSlideIndex = swiper.activeIndex;
                    let bodyElement = document.body;

                    if (activeSlideIndex === 3 || activeSlideIndex === 5 || activeSlideIndex === 0) {
                        bodyElement.classList.add('white-version');
                    } else {
                        bodyElement.classList.remove('white-version');
                    }

                    if (verticalSlider && verticalSlider.swiper && verticalSlider.swiper.activeIndex !== 0) {
                        bodyElement.classList.remove('white-version');
                    }
                },
            },
        });
    }
}

const _module = { init };

export default _module;
