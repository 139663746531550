<template>
    <div class="counter">
        <button class="counter__button" :aria-label="`Уменьшить на ${delta}`" @click.prevent="decrement">
            <span>-</span>
        </button>
        <div class="counter__field">
            <input
                class="counter__input"
                type="number"
                :max="maxValue"
                :maxlength="maxValue.toString().length"
                :value="value"
                @input="onAmountChange"
            />
            <div class="counter__field-units">шт.</div>
        </div>
        <button class="counter__button" :aria-label="`Увеличить на ${delta}`" @click.prevent="increment">
            <span>+</span>
        </button>
    </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue';

export default {
    props: {
        value: {
            type: Number,
            default: 1,
        },
        minValue: {
            type: Number,
            default: 1,
        },
        maxValue: {
            type: Number,
            default: 999,
        },
        delta: {
            type: Number,
            default: 1,
        },
    },

    setup(props, { emit }) {
        const itemAmount = ref(props.value);
        function increment() {
            const nextAmount = Math.max(props.minValue, Math.min(itemAmount.value + props.delta, props.maxValue));
            if (itemAmount.value === nextAmount) return;
            itemAmount.value = nextAmount;
        }

        function decrement() {
            const nextAmount = Math.max(props.minValue, itemAmount.value - props.delta);
            if (itemAmount.value === nextAmount) return;
            itemAmount.value = nextAmount;
        }

        function onAmountChange(event) {
            const amount = Math.min(parseInt(event.target.value.trim() || '0', 10), props.maxValue);
            if (amount === props.minValue - props.delta) {
                itemAmount.value = props.minValue;
            } else {
                itemAmount.value = amount;
            }
            if (event.target.value.trim().length > 3) {
                event.target.value = event.target.value.trim().slice(0, 3);
            }
        }

        watch(itemAmount, (value) => {
            emit('change', { value });
        });

        watch(
            () => props.value,
            (value) => {
                console.log(itemAmount.value);
                itemAmount.value = value;
            },
        );

        watch(
            () => props.minValue,
            (value) => {
                itemAmount.value = Math.max(value, itemAmount.value);
            },
        );

        return { value: itemAmount, increment, decrement, onAmountChange };
    },
};
</script>
<style scoped>
.counter__input {
    width: 40px;
}
</style>
