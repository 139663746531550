/**
 * Конвертирует число в строку типа "валюта"
 *
 * @param  {number} number - число, которое нужно сконвертировать
 * @param  {string} currency - валюта. Например, 'RUB'
 * @returns {string}
 */
export const toCurrency = (number: number, currency = 'RUB', minDigits = 2, maxDigits = 2) =>
    number.toLocaleString('ru-RU', {
        style: 'currency',
        currency,
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
    });
