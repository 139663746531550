import Swiper, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

function init() {
    const slider = document.querySelector('.js-swiper-vertical-about');

    const swiper = new Swiper(slider, {
        direction: 'vertical',
        effect: 'slide',
        simulateTouch: false,
        slidesPerView: 1,
        keyboard: true,
        spaceBetween: 0,
        speed: 800,
        mousewheel: true,
        modules: [Navigation, Pagination, Mousewheel, Keyboard],
        pagination: {
            el: '.js-vertical-about-pagination',
            clickable: true,
            type: 'custom',
            renderCustom: function (swiper, current) {
                if (current === swiper.slides.length) {
                    return '<span class="swiper-pagination-current" ><span>0' + current + "</span></span>"
                } else {
                    return (
                        '<span class="swiper-pagination-current" ><span>0' + current + "</span></span>" +
                        '<span class="swiper-pagination-total"><span>0' + (current + 1) + '</span></span>'
                    );
                }
            },
        },
        navigation: {
            nextEl: '.js-about-vertical-button-next',
            prevEl: '.js-about-vertical-button-prev',
        },
        on: {
            init(swiper) {
                document.body.classList.add('white-version');
            },
            slideChangeTransitionStart: function (swiper) {
                let activeSlideIndex = swiper.activeIndex;
                if (activeSlideIndex === 1) {
                    document.body.classList.remove('white-version');
                }
                if (activeSlideIndex === 0) {
                    document.body.classList.add('white-version');
                }
            },
        },
    });
}

const _module = { init };

export default _module;
