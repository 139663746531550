const mainHorizontalSlider = document.querySelector('.js-main-horizontal-swiper');

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if ((entry.isIntersecting && mainHorizontalSlider && [3, 5, 0].includes(mainHorizontalSlider.swiper.activeIndex))) 
            {
                document.body.classList.add('white-version');
            } else {
                document.body.classList.remove('white-version');
            }
        });
    },
    { rootMargin: '100% 0% -90% 0%' },
);

function init() {
    const headerThemeSwitchEls = Array.from(document.querySelectorAll<HTMLElement>('[data-header-theme="light"]'));

    headerThemeSwitchEls.forEach((el) => {
        observer.observe(el);
    });
}

const _module = { init };

export default _module;
