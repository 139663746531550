import './webpack-imports';
import './sentry';
import './polyfills';
import './custom-elements';
import { createApp } from 'vue';
import AddSaleApp from './components/vue/AddSaleApp.vue';
import AddSaleCatalogApp from './components/vue/AddSaleCatalogApp.vue';
import BasketAddSale from './components/vue/BasketAddSale.vue';
import popups from './inits/popups';
import teamScroll from './inits/team-scroll';
import verticalSwiper from './inits/vertical-swiper';
import verticalSwiperAbout from './inits/vertical-swiper-about';
import mainHorizontalSwiper from './inits/main-horizontal-swiper';
import newsSwiper from './inits/news-horizontal-swiper';
import numbersChanger from './inits/numbers-changer';
import observer from './inits/observer';
import observerAbout from './inits/observer-about';
import mobileMenu from './inits/mobile-menu';
import lazyLoad from './inits/lazyLoad';

document.documentElement.classList.add('js-ready');
const homePage = document.querySelector('.home');
const aboutPage = document.querySelector('.about');

popups.init();
lazyLoad.init();
const addSaleContainer = document.querySelector('.js-add-sale-container');

if (addSaleContainer) {
    createApp(AddSaleApp).mount(addSaleContainer);
}

function mountAddSaleCatalogoItems() {
    const addSaleCatalogContainers = Array.from(
        document.querySelectorAll('.js-add-sale-catalog-container:not([data-v-app])'),
    );

    addSaleCatalogContainers.forEach((container) => {
        createApp(AddSaleCatalogApp).mount(container);
    });
}

teamScroll.init();

if (innerWidth > 768) {
    if (homePage) {
        verticalSwiper.init();
        mainHorizontalSwiper.init();
    }
    if (aboutPage) {
        verticalSwiperAbout.init();
    }
} else {
    if (homePage) {
        observer.init();
        mainHorizontalSwiper.init();
    }
    if (aboutPage) {
        observerAbout.init();
    }
}

if (homePage || aboutPage) {
    newsSwiper.init();
    numbersChanger.init();
}

if (innerWidth < 1280) {
    mobileMenu.init();
}

mountAddSaleCatalogoItems();
$(document).on('catalog-filter-updated', mountAddSaleCatalogoItems);

const basketAddSaleContainer = document.querySelector('.js-basket-add-sale-container');

if (basketAddSaleContainer) {
    createApp(BasketAddSale).mount(basketAddSaleContainer);
}

// module.hot?.accept();
