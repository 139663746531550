<template>
    <div
        ref="popupEl"
        class="lit-popup popup-sm basket-device-add"
        data-lit-popup="basket-device-add"
        data-lit-popup-preset="slide"
    >
        <div class="lit-popup-overlay" data-lit-popup-close="basket-device-add"></div>
        <div class="lit-popup-container basket-device-add-container">
            <button class="popup-close-btn" data-lit-popup-close="basket-device-add" aria-label="Закрыть">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.145 2.707l-.707.707.707-.707zM3.438 2l.707-.707-.707-.707-.707.707.707.707zM31.32 29.884l.707-.707-.707.707zm.707.707l.708.707.707-.707-.707-.707-.708.707zm-1.414 1.414l-.707.707.707.707.707-.707-.707-.707zm-.707-.707l.707-.707-.707.707zM2.731 4.12l-.707.707.707-.707zm-.707-.707l-.708-.707-.707.707.707.707.708-.707zm29.274.707l.707.707-.707-.707zm.707-.707l.707.707.707-.707-.707-.707-.707.707zM4.12 31.298l-.707-.707.707.707zm-.707.707l-.707.707.707.707.707-.707-.707-.707zM2 30.59l-.707-.707-.707.707.707.707L2 30.59zm.707-.707L2 29.177l.707.707zM29.884 2.707l.707.707-.707-.707zM30.59 2l.707-.707-.707-.707-.707.707.707.707zM4.852 2l-.707-.707L2.73 2.707l.707.707L4.852 2zm27.176 27.177L4.852 2 3.438 3.414l27.176 27.177 1.414-1.415zm.708.707l-.708-.707-1.414 1.414.707.707 1.415-1.414zm-1.415 2.828l1.415-1.414-1.415-1.414-1.414 1.414 1.414 1.414zm-2.121-.707l.707.707 1.414-1.414-.707-.707-1.414 1.414zM2.024 4.828L29.2 32.005l1.414-1.414L3.438 3.414 2.024 4.828zm-.708-.707l.708.707 1.414-1.414-.707-.707-1.415 1.414zm1.415-2.828L1.316 2.707l1.415 1.414 1.414-1.414L2.73 1.293zm29.274 3.535l.707-.707-1.414-1.414-.707.707 1.414 1.414zM4.828 32.005L32.005 4.828 30.59 3.414 3.414 30.591l1.414 1.414zm-.707.707l.707-.707-1.414-1.414-.707.707 1.414 1.414zm-2.828-1.414l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414zM2 29.177l-.707.707 1.414 1.414.707-.707L2 29.176zM29.177 2L2 29.177l1.414 1.414L30.591 3.414 29.176 2zm.707-.707L29.177 2l1.414 1.414.707-.707-1.414-1.414zm2.828 1.414l-1.414-1.414-1.414 1.414 1.414 1.414 1.414-1.414z"
                        fill="#002450"
                    />
                </svg>
            </button>

            <div class="basket-device-add-title">Комплект</div>

            <span v-if="isFetching" class="app-spinner basket-device-add__fetching-spinner"></span>
            <template v-else>
                <div class="device-add-popup-price-block">
                    <div class="basket-device-add-price">
                        <div class="b-price">
                            <div v-if="totalPriceDiscounted !== totalPrice" class="b-price__old">{{ totalPrice }}</div>
                            <div class="b-price__current">
                                <span>{{ totalPriceDiscounted }}</span>
                            </div>
                            <div class="b-price__nds">с ндс</div>
                        </div>
                    </div>
                    <div class="app-counter device-add-popup__app-counter">
                        <v-counter :value="setsAmount" @change="onCounterChange"></v-counter>
                    </div>
                    <div>
                        <a v-if="isAddedToBasket" href="/personal/cart/" class="button _th-main">
                            <span class="button__text">В корзину</span>
                        </a>
                        <button v-else class="button _th-main" @click.prevent="addToBasket" :disabled="isLoading">
                            <span v-if="isLoading" class="app-spinner"></span>
                            <span v-else class="button__text">Добавить в корзину</span>
                        </button>
                    </div>
                </div>
                <add-sale-items-list
                    v-if="items.length > 0"
                    :items="items"
                    @item-counter-change="onItemCounterChange"
                ></add-sale-items-list>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue';
import delegate from 'delegate';
import VCounter from './AppCounter.vue';
import AddSaleItemsList from './AddSaleItemsList.vue';
import { toCurrency } from '../../utils/to-currency';
import popups from '../../inits/popups';

function arraysEqual(a: any, b: any) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export default {
    components: { VCounter, AddSaleItemsList },

    setup() {
        const ids = ref([]);
        const items = ref([]);
        const isLoading = ref<boolean>(false);
        const isFetching = ref<boolean>(false);
        const isAddedToBasket = ref<boolean>(false);
        const popupEl = ref<HTMLElement>();
        const containerEl = ref<HTMLElement>();
        const closeTimeoutRef = ref();
        const setsAmount = ref(1);

        const totalPrice = computed(() =>
            toCurrency(items.value.reduce((acc, item) => acc + item.price * item.amount, 0)),
        );

        const totalPriceDiscounted = computed(() =>
            toCurrency(
                items.value.reduce((acc, item) => {
                    return acc + item.priceDiscounted * item.amount;
                }, 0),
            ),
        );

        function addToBasket() {
            clearTimeout(closeTimeoutRef.value);

            const _items = items.value.map((item) => ({
                id: item.id,
                quantity: item.amount,
            }));
            isLoading.value = true;

            return fetch('/local/ajax/basket.php', {
                method: 'post',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                body: JSON.stringify({
                    action: 'add-multiple',
                    items: _items,
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }

                    throw new Error('Что-то пошло не так');
                })
                .then((response) => {
                    (window as any).updateSmallBask?.();
                    isAddedToBasket.value = true;
                    window.location.reload();

                    // closeTimeoutRef.value = setTimeout(() => {
                    //     const popup = popups.getInstanceByElement(popupEl.value);
                    //     popup?.close();
                    // }, 4000);
                })
                .catch((err) => {
                    //
                    throw err;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        }

        function onCounterChange({ value }) {
            if (value) {
                setsAmount.value = value;
            }
        }

        function onItemCounterChange({ id, value }) {
            const exactItem = items.value.find((item) => item.id === id);

            if (typeof value === 'number' && exactItem) {
                exactItem.amount = value;
            }
        }

        onMounted(() => {
            popups.init(containerEl.value);

            const popup = popups.getInstanceByElement(popupEl.value);

            if (popup) {
                popup.on('open', () => {
                    clearTimeout(closeTimeoutRef.value);
                });
            }
        });

        watch(ids, (value, prevValue) => {
            if (!arraysEqual(prevValue, value)) {
                isFetching.value = true;

                fetch('/local/ajax/basket.php', {
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({
                        action: 'get-add-products',
                        items: value,
                    }),
                })
                    .then((res) => {
                        if (res.ok) {
                            return res.json();
                        }

                        throw new Error('Что-то пошло не так');
                    })
                    .then((response) => {
                        if (response.data.items) {
                            items.value = response.data.items.map((item) => ({
                                ...item,
                                amount: setsAmount.value * (typeof item.quantity === 'number' ? item.quantity : 1),
                            }));
                        }
                    })
                    .catch((err) => {
                        //
                        throw err;
                    })
                    .finally(() => {
                        isFetching.value = false;
                    });
            }
        });

        watch(setsAmount, (value) => {
            items.value.forEach((item) => {
                item.amount = value * (typeof item.quantity === 'number' ? item.quantity : 1);
            });
        });

        onMounted(() => {
            delegate(document, '.js-basket-get-adds', 'click', (event: any) => {
                const idsArr = event.delegateTarget.dataset.ids ? JSON.parse(event.delegateTarget.dataset.ids) : [];
                ids.value = idsArr;
            });
        });

        onBeforeUnmount(() => {
            popups.destroy(containerEl.value);
        });

        return {
            items,
            totalPrice,
            totalPriceDiscounted,
            addToBasket,
            isLoading,
            isFetching,
            isAddedToBasket,
            toCurrency,
            onItemCounterChange,
            popupEl,
            containerEl,
            setsAmount,
            onCounterChange,
        };
    },
};
</script>

<style lang="scss">
// @import '../../../css/base/variables';
@import '../../../css/utils/mixins';

.basket-device-add {
    .b-price {
        @media screen and (max-width: 1600px) {
            font-size: 20px;
        }

        @media screen and (max-width: 1366px) {
            font-size: 18px;
        }
    }

    .b-price__old,
    .b-price__nds {
        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
}

.basket-device-add-container {
    min-height: 50vh;
    min-height: Min(700px, 50vh);
}

.basket-device-add-title {
    line-height: 1.56;
    font-size: 32px;
    letter-spacing: -0.04em;
    margin-bottom: 24px;

    @media screen and (max-width: 1680px) {
        font-size: 28px;
    }

    @media screen and (max-width: 1366px) {
        font-size: 24px;
    }

    @media screen and (max-width: 1024px) {
        padding-right: 50px;
        line-height: 1.2;
        margin-bottom: 32px;
    }
}

.device-items-list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    list-style: none;
    padding: 0;

    @media screen and (max-width: 1440px) {
        margin: -5px;
    }
}

.device-items-list__item {
    margin: 10px;
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1440px) {
        margin: 5px;
        width: calc(25% - 10px);
    }

    @media screen and (max-width: 1279px) {
        width: calc(100% / 3 - 10px);
    }

    @media screen and (max-width: 1024px) {
        width: calc(50% - 10px);
    }

    @media screen and (max-width: 576px) {
        width: calc(100% - 10px);
    }

    > .device-item {
        flex: 1 1 auto;
    }
}

.device-add-popup-price-block {
    margin-top: 41px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(#698197, 0.2);
    border-bottom: 1px solid rgba(#698197, 0.2);
    margin-bottom: 34px;

    @media screen and (max-width: 1440px) {
        margin-top: 22px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-top: 0;

        .button {
            width: 100%;
            margin-top: 13px;
        }
    }
}

.device-add-popup__app-counter {
    @media screen and (max-width: 767px) {
        margin-top: 33px;
    }

    .counter {
        background-color: #fff;
    }
}

.basket-device-add__fetching-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: #2562f6;
    border-left-color: rgba(#2562f6, 0.1);
}
</style>
