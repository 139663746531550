import gsap from 'gsap';

function init() {
    const slides = Array.from(document.querySelectorAll<HTMLElement>('.dcounter-slide'));
    let currentSlide = 0;

    function showSlide(slideIndex: number) {
        slides.forEach((slide, index) => {
            gsap.to(slide, { opacity: index === slideIndex ? 1 : 0, duration: 0.2 });
        });
    }

    function nextSlide() {
        const nextSlideIndex = (currentSlide + 1) % slides.length;
        showSlide(nextSlideIndex);
        currentSlide = nextSlideIndex;
    }

    function startSlideshow() {
        nextSlide();
        gsap.delayedCall(3, startSlideshow);
    }

    startSlideshow();
}

const _module = { init };

export default _module;
