import Swiper, { Navigation, Pagination } from 'swiper';

function init() {
    const slider = document.querySelector('.js-news-horizontal-swiper');

    const swiper = new Swiper(slider, {
        direction: 'horizontal',
        effect: 'slide',
        keyboard: true,
        spaceBetween: 0,
        speed: 400,
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination-n',
            clickable: true,
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next-n',
            prevEl: '.swiper-button-prev-n',
        },
    });
}

const _module = { init };

export default _module;
