<template>
    <div
        ref="sliderContainer"
        v-if="items.length > 0"
        class="devices-add-slider swiper-container"
        :class="{ 'devices-add-slider--initialized': !!slider }"
    >
        <div class="devices-add-slider__top">
            <button class="devices-add-slider__navigation-btn swiper-button-prev-n" aria-label="Предыдущий слайд">
                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 2L2 10.3474L10 18" stroke="#002450" stroke-width="2" stroke-linecap="square" />
                </svg>
            </button>
            <div class="devices-add-slider__pagination"></div>
            <button class="devices-add-slider__navigation-btn swiper-button-next-n" aria-label="Следующий слайд">
                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 2L2 10.3474L10 18" stroke="#002450" stroke-width="2" stroke-linecap="square" />
                </svg>
            </button>
        </div>
        <div class="swiper-wrapper devices-add-slider__wrapper">
            <div v-for="item in items" :key="item.id" class="swiper-slide devices-add-slider__slide">
                <add-sale-item
                    :id="item.id"
                    :img="item.img"
                    :description="item.description"
                    :name="item.name"
                    :href="item.href"
                    :price="item.price"
                    :price-discounted="item.priceDiscounted"
                    :amount="item.amount"
                    :min-value="item.minValue"
                    @counter-change="onItemCounterChange"
                ></add-sale-item>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import VCounter from './AppCounter.vue';
import AddSaleItem from './AddSaleItem.vue';

function getSlidesPerView() {
    switch (true) {
        case matchMedia('(max-width: 576px)').matches:
            return 1;
        case matchMedia('(max-width: 767px)').matches:
            return 2;
        case matchMedia('(max-width: 1279px)').matches:
            return 3;
        default:
            return 4;
    }
}

export default {
    components: { VCounter, AddSaleItem },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    setup(props, { emit }) {
        const realItemsLength = ref(props.items.length);
        const sliderContainer = ref<HTMLElement>();
        const slider = ref<any>();

        function onItemCounterChange({ id, value }) {
            emit('item-counter-change', { id, value });
        }

        function attemptToInitSlider() {
            if ('Swiper' in window) {
                if (realItemsLength.value > getSlidesPerView()) {
                    if (!slider.value) {
                        slider.value = new (window as any).Swiper(sliderContainer.value, {
                            pagination: '.devices-add-slider__pagination',
                            nextButton: '.swiper-button-next-n',
                            prevButton: '.swiper-button-prev-n',
                            paginationClickable: true,
                            spaceBetween: 0,
                            loop: false,
                            speed: 400,
                            slidesPerView: 'auto',
                        });
                    }
                } else if (slider.value) {
                    slider.value.destroy(slider.value);
                    slider.value = null;
                }
            }
        }

        onMounted(() => {
            attemptToInitSlider();
            window.addEventListener('resize', attemptToInitSlider);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', attemptToInitSlider);

            if (slider.value) {
                slider.value.destroy();
                slider.value = null;
            }
        });

        return { sliderContainer, slider, onItemCounterChange };
    },
};
</script>

<style lang="scss">
@import '../../../css/base/variables';
@import '../../../css/utils/mixins';

.devices-add-slider {
    height: auto;

    &.devices-add-slider--initialized {
        margin-left: -40px;
        margin-right: -40px;
        width: calc(100% + 80px);
        padding-left: 40px;
        padding-right: 40px;

        @media screen and (max-width: 1024px) {
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
            padding-left: 16px;
            padding-right: 16px;
        }

        .swiper-wrapper {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }

        .devices-add-slider__slide {
            width: calc(25% - 40px);

            @media screen and (max-width: 1440px) {
                width: calc(25% - 20px);
            }

            @media screen and (max-width: 1279px) {
                width: calc(100% / 3 - 20px);
            }

            @media screen and (max-width: 1024px) {
                width: calc(50% - 20px);
            }

            @media screen and (max-width: 576px) {
                width: calc(100% - 20px);
            }

            &:not(:last-child) {
                margin-right: 20px;

                @media screen and (max-width: 1440px) {
                    margin-right: 10px;
                }
            }
        }
    }
}

.devices-add-slider__wrapper {
    height: auto;
}

.devices-add-slider__slide {
    display: flex;
    flex-direction: column;
    height: auto;
    width: calc(25% - 8px);

    @media screen and (max-width: 1279px) {
        width: calc(100% / 3 - 8px);
    }

    @media screen and (max-width: 767px) {
        width: calc(50% - 8px);
    }

    @media screen and (max-width: 576px) {
        width: calc(100% - 8px);
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    .device-item {
        flex: 1 1 auto;
    }
}

.devices-add-slider__top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;

    .devices-add-slider:not(.devices-add-slider--initialized) & {
        display: none;
    }
}

.devices-add-slider__pagination {
    margin-left: 40px;
    margin-right: 40px;

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
}

.devices-add-slider__navigation-btn {
    @include clickable();
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    @mixin hover() {
        svg {
            path {
                stroke: #005fff;
            }
        }
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }

    &:focus-visible {
        outline: 0;
    }

    &.swiper-button-disabled {
        opacity: 0.5;
        cursor: auto;
        pointer-events: none;
    }

    &.swiper-button-next-n {
        svg {
            transform: rotate(180deg);
        }
    }

    svg {
        path {
            transition: stroke 0.2s ease;
        }
    }
}
</style>
