<template>
    <div ref="containerEl" class="add-sale-catalog-buy-wrapper">
        <div class="add-sale-catalog-buy">
            <div class="add-sale-catalog-buy-left">
                <div v-if="setPriceDiscounted !== setPrice" class="b-price__old">{{ setPrice }}</div>
                <div itemprop="offers" itemscope itemtype="http://schema.org/Offer" class="b-product__price">
                    <div class="b-product__price-current">{{ setPriceDiscounted }}</div>
                    <button
                        class="app-link app-link--underlined add-sale-catalog-show-set-btn"
                        :data-lit-popup-open="`device-add-${_uid}`"
                    >
                        Смотреть комплект
                    </button>
                    <div class="b-product__price-nds">с ндс</div>
                    <meta itemprop="price" :content="setPrice" />
                    <meta itemprop="priceCurrency" content="RUB" />
                </div>
            </div>
            <div class="add-sale-catalog-buy-right">
                <button
                    v-if="!isCounterOpen"
                    class="buy-block__button button-icon _th-second add-sale-catalog-buy-button"
                    @click="openCounter"
                    :disabled="isLoading || isOpening"
                >
                    <div v-if="isLoading" class="app-spinner"></div>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 24h2V13h11v-2H13V0h-2v11H0v2h11v11z"
                            fill="#fff"
                        />
                    </svg>
                </button>
                <div v-else class="add-sale-catalog-counter-block">
                    <div class="add-sale-catalog-counter" :class="{ 'is-visible': isCounterOpen }">
                        <v-counter :value="setsAmount" @change="onCounterChange"></v-counter>
                    </div>
                    <button
                        class="buy-block__button button-icon _th-second add-sale-catalog-buy-button"
                        @click.prevent="addtoBasketAndCloseCounter"
                        :disabled="isLoading || isOpening"
                    >
                        <div v-if="isLoading" class="app-spinner"></div>
                        <div v-else class="button__text">
                            <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.5 9.5l6.029 4.631L18.493 1.463"
                                    stroke-width="2"
                                    stroke-linecap="square"
                                    stroke="#FCFDFD"
                                    fill="none"
                                />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <div
            ref="popupEl"
            class="lit-popup popup-sm"
            :data-lit-popup="`device-add-${_uid}`"
            data-lit-popup-preset="slide"
        >
            <div class="lit-popup-overlay" :data-lit-popup-close="`device-add-${_uid}`"></div>
            <div class="lit-popup-container">
                <button class="popup-close-btn" :data-lit-popup-close="`device-add-${_uid}`" aria-label="Закрыть">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.145 2.707l-.707.707.707-.707zM3.438 2l.707-.707-.707-.707-.707.707.707.707zM31.32 29.884l.707-.707-.707.707zm.707.707l.708.707.707-.707-.707-.707-.708.707zm-1.414 1.414l-.707.707.707.707.707-.707-.707-.707zm-.707-.707l.707-.707-.707.707zM2.731 4.12l-.707.707.707-.707zm-.707-.707l-.708-.707-.707.707.707.707.708-.707zm29.274.707l.707.707-.707-.707zm.707-.707l.707.707.707-.707-.707-.707-.707.707zM4.12 31.298l-.707-.707.707.707zm-.707.707l-.707.707.707.707.707-.707-.707-.707zM2 30.59l-.707-.707-.707.707.707.707L2 30.59zm.707-.707L2 29.177l.707.707zM29.884 2.707l.707.707-.707-.707zM30.59 2l.707-.707-.707-.707-.707.707.707.707zM4.852 2l-.707-.707L2.73 2.707l.707.707L4.852 2zm27.176 27.177L4.852 2 3.438 3.414l27.176 27.177 1.414-1.415zm.708.707l-.708-.707-1.414 1.414.707.707 1.415-1.414zm-1.415 2.828l1.415-1.414-1.415-1.414-1.414 1.414 1.414 1.414zm-2.121-.707l.707.707 1.414-1.414-.707-.707-1.414 1.414zM2.024 4.828L29.2 32.005l1.414-1.414L3.438 3.414 2.024 4.828zm-.708-.707l.708.707 1.414-1.414-.707-.707-1.415 1.414zm1.415-2.828L1.316 2.707l1.415 1.414 1.414-1.414L2.73 1.293zm29.274 3.535l.707-.707-1.414-1.414-.707.707 1.414 1.414zM4.828 32.005L32.005 4.828 30.59 3.414 3.414 30.591l1.414 1.414zm-.707.707l.707-.707-1.414-1.414-.707.707 1.414 1.414zm-2.828-1.414l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414zM2 29.177l-.707.707 1.414 1.414.707-.707L2 29.176zM29.177 2L2 29.177l1.414 1.414L30.591 3.414 29.176 2zm.707-.707L29.177 2l1.414 1.414.707-.707-1.414-1.414zm2.828 1.414l-1.414-1.414-1.414 1.414 1.414 1.414 1.414-1.414z"
                            fill="#002450"
                        />
                    </svg>
                </button>

                <!-- <div class="device-add-title">{{ title }}</div> -->

                <div class="device-add-popup-price-block">
                    <div class="device-add-price">
                        <div class="b-price">
                            <div v-if="totalPriceDiscounted !== totalPrice" class="b-price__old">
                                {{ totalPrice }}
                            </div>
                            <div class="b-price__current">
                                <span>{{ totalPriceDiscounted }}</span>
                            </div>
                            <div class="b-price__nds">с ндс</div>
                        </div>
                    </div>
                    <div class="app-counter device-add-popup__app-counter">
                        <v-counter :value="setsAmount" @change="onCounterChange"></v-counter>
                    </div>
                    <div>
                        <a v-if="isAddedToBasket" href="/personal/cart/" class="button _th-main">
                            <span class="button__text">В корзину</span>
                        </a>
                        <button v-else class="button _th-main" @click.prevent="addToBasket" :disabled="isLoading">
                            <span v-if="isLoading" class="app-spinner"></span>
                            <span v-else class="button__text">Добавить в корзину</span>
                        </button>
                    </div>
                </div>
                <add-sale-items-list
                    v-if="items.length > 0"
                    :items="items"
                    @item-counter-change="onItemCounterChange"
                ></add-sale-items-list>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable max-lines */
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import VCounter from './AppCounter.vue';
import AddSaleItemsList from './AddSaleItemsList.vue';
import { toCurrency } from '../../utils/to-currency';
import popups from '../../inits/popups';
// import type { AddSaleCatalogItemData } from '../../types';

let uid = 0;

export default {
    components: { VCounter, AddSaleItemsList },

    setup() {
        // const data = ref<AddSaleCatalogItemData | null>();
        const setsAmount = ref(0);
        const items = ref([]);
        const isLoading = ref<boolean>(false);
        const isOpening = ref<boolean>(false);
        const isAddedToBasket = ref<boolean>(false);
        const popupEl = ref<HTMLElement>();
        const containerEl = ref<HTMLElement>();
        const closeTimeoutRef = ref();
        const isCounterOpen = ref(false);
        const _uid = ref(uid++);

        const setPrice = computed(() =>
            toCurrency(
                items.value.reduce((acc, item) => acc + item.price * item.amount, 0),
                undefined,
                2,
            ).replace(',', '.'),
        );

        const setPriceDiscounted = computed(() =>
            toCurrency(
                items.value.reduce((acc, item) => acc + item.priceDiscounted * item.amount, 0),
                undefined,
                2,
            ).replace(',', '.'),
        );

        const totalPrice = computed(() =>
            toCurrency(
                items.value.reduce((acc, item) => acc + item.price * item.amount, 0),
                undefined,
                2,
            ),
        );

        const totalPriceDiscounted = computed(() =>
            toCurrency(items.value.reduce((acc, item) => acc + item.priceDiscounted * item.amount, 0)),
        );

        function onCounterChange({ value }) {
            if (value) {
                setsAmount.value = value;
            }
        }

        function addToBasket() {
            clearTimeout(closeTimeoutRef.value);

            const _items = items.value.map((item) => ({
                id: item.id,
                quantity: item.amount,
            }));

            isLoading.value = true;

            return fetch('/local/ajax/basket.php', {
                method: 'post',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                body: JSON.stringify({
                    action: 'add-multiple',
                    items: _items,
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }

                    throw new Error('Что-то пошло не так');
                })
                .then((response) => {
                    (window as any).updateSmallBask?.();
                    isAddedToBasket.value = true;
                    setsAmount.value = 1;

                    closeTimeoutRef.value = setTimeout(() => {
                        const popup = popups.getInstanceByElement(popupEl.value);
                        popup?.close();
                    }, 4000);

                    if (response.message) {
                        //
                    }
                })
                .catch((err) => {
                    //
                    throw err;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        }

        function openCounter() {
            isCounterOpen.value = true;
            isOpening.value = true;

            setTimeout(() => {
                isOpening.value = false;
            }, 300);
        }

        async function addtoBasketAndCloseCounter() {
            await addToBasket();
            isCounterOpen.value = false;
        }

        function onItemCounterChange({ id, value }) {
            const exactItem = items.value.find((item) => item.id === id);

            if (typeof value === 'number' && exactItem) {
                if (value === 0 && !items.value.filter((item) => item !== exactItem).some((item) => item.amount > 0)) {
                    exactItem.minValue = 1;
                    return;
                }

                exactItem.minValue = 0;
                exactItem.amount = value;
            }
        }

        watch(setsAmount, (value) => {
            items.value.forEach((item) => {
                item.amount = value * (typeof item.quantity === 'number' ? item.quantity : 1);
            });
        });

        onMounted(() => {
            const dataElContent = containerEl.value
                .closest('.catalog__item')
                ?.querySelector('script.js-set-data')?.textContent;

            if (dataElContent) {
                const _data = JSON.parse(dataElContent);

                if (_data) {
                    setsAmount.value = _data.startSetAmount;
                    items.value = [...(_data.mainItem ? [_data.mainItem] : []), ..._data.items].map((item) => ({
                        ...item,
                        amount: setsAmount.value * (typeof item.quantity === 'number' ? item.quantity : 1),
                        minValue: 0,
                    }));
                }
            }
        });

        onMounted(() => {
            popups.init(containerEl.value);

            const popup = popups.getInstanceByElement(popupEl.value);

            if (popup) {
                popup.on('open', () => {
                    clearTimeout(closeTimeoutRef.value);
                });

                popup.on('close-complete', () => {
                    setTimeout(() => {
                        isAddedToBasket.value = false;
                    }, 300);
                });
            }
        });

        onMounted(() => {
            containerEl.value.closest('.b-product__buy')?.classList.add('b-product__buy--wide');
        });

        onBeforeUnmount(() => {
            popups.destroy(containerEl.value);
        });

        return {
            setPrice,
            setPriceDiscounted,
            items,
            setsAmount,
            totalPrice,
            totalPriceDiscounted,
            onCounterChange,
            addToBasket,
            isLoading,
            isOpening,
            isAddedToBasket,
            toCurrency,
            popupEl,
            containerEl,
            onItemCounterChange,
            isCounterOpen,
            openCounter,
            addtoBasketAndCloseCounter,
            _uid,
        };
    },
};
</script>

<style lang="scss">
@import '../../../css/base/variables';
@import '../../../css/utils/mixins';

.add-sale-catalog-buy-wrapper {
    display: flex;
    flex-direction: column;
}

.add-sale-catalog-buy {
    width: 100%;
    display: flex;
}

.add-sale-catalog-buy-right {
    margin-left: auto;
}

.add-sale-catalog-buy-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    &:disabled {
        opacity: 0.65;
    }

    .app-spinner {
        width: 20px;
        height: 20px;
    }

    .button__text {
        width: 100%;
    }
}

.add-sale-catalog-show-set-btn {
    margin-bottom: 5px;
    font-size: 14px;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: #f5a623;
    transition: color 0.2s ease;

    &:focus:not(:focus-visible) {
        outline: 0;
    }

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:hover {
            color: #005fff;
        }
    }

    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }
}

.add-sale-catalog-counter-block {
    display: flex;
    align-items: center;

    .counter {
        flex: 1 1 auto;
        margin-right: 10px;
    }
}

.add-sale-catalog-counter {
    transform-origin: 100% 50%;

    &.is-visible {
        animation: slideIn-x 0.2s ease-in-out;
    }
}

.device-add-popup-price-block {
    margin-top: 41px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(#698197, 0.2);
    border-bottom: 1px solid rgba(#698197, 0.2);
    margin-bottom: 34px;
    position: relative;

    @media screen and (max-width: 1440px) {
        margin-top: 22px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-top: 0;

        .button {
            width: 100%;
            margin-top: 13px;
        }
    }
}

.device-add-popup__app-counter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 767px) {
        transform: none;
        position: static;
        margin-top: 33px;
    }

    .counter {
        background-color: #fff;
    }
}

.b-product__buy.b-product__buy--wide {
    width: 100%;

    .b-product__price-current {
        line-height: 1.2;
    }
}

.app-link {
    --bg-size: 0% 100%;
    --bg-size-hover: 100% 100%;
    --underline-height: 1px;
    display: inline;
    background-image: linear-gradient(
        transparent calc(100% - var(--underline-height)),
        currentColor var(--underline-height)
    );
    background-repeat: no-repeat;
    background-size: var(--bg-size);
    transition: background-size 0.4s ease-in-out, color 0.2s ease, opacity 0.2s ease;

    @mixin hover() {
        background-size: var(--bg-size-hover);
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (pointer: fine) {
        &:hover {
            @include hover();
        }
    }

    &.app-link--underlined {
        --bg-size: 100% 100%;
        --bg-size-hover: 0% 100%;
    }
}
</style>
