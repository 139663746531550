import LitPopup from 'lit-popup';

const map = new Map<Element, LitPopup>();
const NO_SCROLL_CLASS = 'no-scroll';

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-lit-popup]'));

    elements.forEach((el) => {
        const name = el.dataset.litPopup;
        const shouldLockScroll = el.dataset.lockScroll !== 'no';

        let wasBodyLocked = false;

        const lockBodyScroll = () => {
            wasBodyLocked = document.documentElement.classList.contains(NO_SCROLL_CLASS);

            if (!wasBodyLocked) {
                document.documentElement.classList.add(NO_SCROLL_CLASS);
            }
        };

        const unlockBodyScroll = () => {
            if (!wasBodyLocked) {
                document.documentElement.classList.remove(NO_SCROLL_CLASS);
            }
        };

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        const popup = new LitPopup(name, {
            onOpen: () => {
                if (shouldLockScroll) {
                    lockBodyScroll();
                }
                const focusableOnOpenElement = el.querySelector<HTMLInputElement>('[data-focus-on-popup-open]');

                if (focusableOnOpenElement) {
                    setTimeout(() => focusableOnOpenElement.focus(), 100);
                }
            },
            onCloseComplete: () => {
                if (shouldLockScroll) {
                    unlockBodyScroll();
                }
            },
        });

        map.set(el, popup);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('[data-lit-popup]'));

    elements.forEach((el) => {
        const popup = map.get(el);

        if (popup) {
            popup.destroy();
            map.delete(el);
        }
    });
}

function getInstanceByElement(element?: Element | null) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
