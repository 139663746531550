function init() {
    const accordionItems = document.querySelectorAll('.js-menu-accordion-item');
    const menuOverlay = document.querySelector('.main-nav-overlay');
    const social = document.querySelector('.scl-ntwrk');
    const menuContainer = document.querySelector('.js-menu-container');
    const socialClone = social?.cloneNode(true);
    const headers = document.querySelectorAll<HTMLElement>('.js-menu-accordion-header');

    if (menuOverlay && socialClone) {
        menuOverlay.classList.add('open');
        menuOverlay.appendChild(socialClone);
        socialClone.classList.add('menu-scl-ntwrk');
        const socialCloneChildren = Array.from(socialClone?.children);
        socialClone.removeChild(socialCloneChildren[0]);
        socialClone.removeChild(socialCloneChildren[4]);
    }

    accordionItems.forEach((item) => {
        const header = item.querySelector('.js-menu-accordion-header');
        const linkHeader = item.querySelector('.js-hdr');
        if (header) {
            const ulElement = header.querySelector('ul');
            const svgContainer = header.querySelector('.js-menu-svg-container');

            let currentHeight = ulElement?.scrollHeight;
            if (currentHeight && currentHeight < 200) {
                currentHeight = 200;
            }
            linkHeader?.removeAttribute('href');
            const svg = `<svg class="mobile-menu-arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Frame 15"><path id="Path 3" d="M10.5 3.75L6 8.25L1.5 3.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>`;
            if (svgContainer) {
                svgContainer.innerHTML = svg;
            }

            header.addEventListener('click', function () {
                accordionItems.forEach((otherItem) => {
                    if (otherItem !== item) {
                        otherItem.classList.remove('accordion-active');
                    }
                });

                item.classList.toggle('accordion-active');

                if (headers) {
                    headers.forEach((header) => {
                        header.style.backgroundColor = '#002450';
                    });
                }

                if (item.classList.contains('accordion-active') && menuContainer) {
                    menuContainer.style.zIndex = '1';
                } else if (menuContainer) {
                    menuContainer.style.zIndex = '0';
                }

                document.documentElement.style.setProperty('--menu-item-height', `${currentHeight + 'px'}`);
            });
        }
    });
    menuOverlay?.classList.remove('open');
}

const _module = { init };

export default _module;
